import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-white shadow-md">
      <div className="container mx-auto px-4 py-6">
        <div className="flex justify-between items-center">
          <Link to="/" className="text-2xl font-bold text-blue-600">Deepfake Help</Link>
          
          {/* Mobile menu button */}
          <button 
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden p-2 rounded-md hover:bg-gray-100 focus:outline-none"
          >
            <svg 
              className="h-6 w-6" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              {isMenuOpen ? (
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>

          {/* Desktop navigation */}
          <nav className="hidden md:flex items-center space-x-6">
            <Link to="/find-lawyer" className="bg-blue-400 text-white px-6 py-2 rounded-full hover:bg-blue-300 transition-colors">
              Get Help
            </Link>
            <Link to="/guides/deepfake-takedown" className="text-gray-600 hover:text-blue-600 transition-colors">Guides</Link>
            <Link to="/contact" className="text-gray-600 hover:text-blue-600 transition-colors">About Us</Link>
            <Link to="/contact" className="bg-green-500 text-white px-6 py-2 rounded-full hover:bg-green-600 transition-colors">
              Report a School
            </Link>
          </nav>
        </div>

        {/* Mobile navigation */}
        {isMenuOpen && (
          <nav className="md:hidden mt-4 space-y-4">
            <Link 
              to="/find-lawyer" 
              className="block bg-blue-400 text-white px-6 py-2 rounded-full hover:bg-blue-300 transition-colors text-center"
            >
              Get Help
            </Link>
            <Link 
              to="/guides/deepfake-takedown" 
              className="block text-gray-600 hover:text-blue-600 transition-colors text-center py-2"
            >
              Guides
            </Link>
            <Link 
              to="/contact" 
              className="block text-gray-600 hover:text-blue-600 transition-colors text-center py-2"
            >
              About Us
            </Link>
            <Link 
              to="/contact" 
              className="block bg-green-500 text-white px-6 py-2 rounded-full hover:bg-green-600 transition-colors text-center"
            >
              Report a School
            </Link>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;