import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser'; // Import EmailJS
import Footer from './Footer'; // Import the Footer component

const ContactUs = () => {
  const [message, setMessage] = useState(''); // State for message
  const [success, setSuccess] = useState(false); // State for success message

  useEffect(() => {
    // Load reCAPTCHA script
    const script = document.createElement('script');
    script.src = "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    
    window.onloadCallback = () => {
      // reCAPTCHA is ready
      window.grecaptcha.render('recaptcha', {
        'sitekey': '6LcVaFQqAAAAAKVflKudLbTplwXQ02o7_eyJYR2A' // Replace with your site key
      });
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const recaptchaResponse = window.grecaptcha.getResponse(); // Get reCAPTCHA response

    if (!recaptchaResponse) {
      alert('Please complete the reCAPTCHA');
      return; // Prevent form submission if reCAPTCHA is not completed
    }

    // EmailJS send function
    emailjs.send('service_n3tkv28', 'template_hic47zi', { message, recaptchaResponse }, 'E2EFYM3hRu6f6FEUR')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setSuccess(true); // Set success state
        setMessage(''); // Clear message field
      }, (err) => {
        console.error('FAILED...', err);
      });
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Main Content */}
      <main className="container mx-auto px-4 py-8">
        {/* Information Section */}
        <section className="mt-8">
          <h2 className="text-4xl font-bold mb-8 text-blue-600">Why I Built DeepfakeHelp.com</h2>
          <p className="text-lg text-gray-700 mb-4">
            DeepFakeHelp.com is a community-driven site that relies on public and anonymous reports on deepfake cases at schools and universities.
          </p>
          <p className="text-gray-700 mb-4">
            A year ago, I started to collect and introduce AI tools to help entrepreneurs and creators boost their productivity. Over time, it became clear that many of these tools have dual uses. Like a knife, they can either be used to create a masterpiece Michelin Star dish or cause harm.
          </p>
          <p className="text-gray-700 mb-4">
          Now, seeing hundreds of cases targeting schoolgirls in just a month, I wondered: Am I the only one who views this as an extreme attack? It turned out that while others I spoke with agreed, an even bigger problem existed.
          </p>
          <p className="text-gray-700 mb-4">
          No one knew. There was no awareness that this was even possible.
          </p>
          <p className="text-gray-700 mb-4">
          So, I set out to gather all cases from the community, with the first goal of raising awareness. And not only stop there -- to provide guidance for victims..
          </p>
          <p className="text-gray-700 mb-4">
          To achieve this, I turned to AI. Sort of using AI to fight AI. So over a weekend, I learned how to put develop my first website with the help of an coding agent. Well… can I really say I "developed" if I was prompting an AI tool to build it for me? That’s a topic for another time.
          </p>
          <p className="text-gray-700 mb-4">
          And that’s how DeepfakeHelp was born.
          </p>
          <p className="text-gray-700 mb-4">
          We've got more community members joining to report their local school cases. And it's already getting attention globally.
          </p>
          <p className="text-gray-700 mb-4">
          We'll make the future bright!         
          </p>
          <p className="text-gray-700 mb-4">
          Thank you.       
          </p>
          {/* Add more paragraphs as needed */}
        </section>
        
        {/* Call to Action Section */}
        <div className="max-w-2xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden mt-12">
          <div className="p-6">
            <h2 className="text-xl font-semibold mb-4">Want to report anonymously a new school/university case? Or got more thoughts and ideas?</h2>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">Send us a Message!</label>
                <textarea 
                  id="message" 
                  name="message" 
                  rows="4" 
                  value={message} 
                  onChange={(e) => setMessage(e.target.value)} // Update state on change
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  required // Make it a required field
                  placeholder="If you're reporting a school name, do provide your supporting source" // Guidance text
                ></textarea>
              </div>
              <div id="recaptcha"></div> {/* reCAPTCHA widget */}
              <div>
                <button type="submit" className="w-full bg-blue-400 text-white px-4 py-2 rounded hover:bg-blue-300 transition-colors duration-300">
                  Send Message
                </button>
              </div>
              {success && <p className="text-green-500">Thank you for your message; we've successfully received it!</p>} {/* Success message */}
            </form>
          </div>
        </div>

        {/* Button Links Section */}
        <section className="max-w-6xl mx-auto px-4 py-12 mt-12">
          <div className="flex justify-center space-x-4">
            <Link to="/find-lawyer">
              <div className="bg-blue-300 rounded-lg p-4 shadow-lg hover:bg-blue-400 transition duration-200 cursor-pointer flex items-center justify-center w-64">
                <h3 className="text-xl font-bold text-white text-center">Supporting Organisation</h3>
              </div>
            </Link>
            <Link to="/guides/deepfake-takedown">
              <div className="bg-blue-300 rounded-lg p-4 shadow-lg hover:bg-blue-400 transition duration-200 cursor-pointer flex items-center justify-center w-64">
                <h3 className="text-xl font-bold text-white text-center">Removal Guides</h3>
              </div>
            </Link>
          </div>
        </section>
      </main>
    
    </div>
  );
};

export default ContactUs;
