import React, { useState, useCallback, useEffect } from 'react';
import { HashRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import './App.css';
import FindLawyer from './FindLawyer';
import GuidesOverview from './GuidesOverview';
import ContactUs from './ContactUs';
import MapChart from './components/MapChart';
import casesData from './casesData.json'; // Import the JSON data
import UnderstandingDeepfakes from './guides/UnderstandingDeepfakes'; // Import individual guide components
import GuideTakeDown from './guides/GuideTakeDown'; // Adjust the path as necessary
import AnimatedMapComponent from './components/AnimatedMapComponent';
import Header from './Header'; // Import the Header component
import Footer from './Footer'; // Import the Footer component




const MapApp = () => {
  return (
    <div className="app">
      <AnimatedMapComponent casesData={casesData} />
    </div>
  );
};

const DeepfakeVictimSupportLanding = () => {
  const navigate = useNavigate(); // Initialize navigate
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const allData = casesData;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showBlur, setShowBlur] = useState(true);


  useEffect(() => {
    // Filter data based on search term
    const filtered = casesData.filter(item =>
      item.schoolName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.country.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchTerm]);


  const toggleMenu = useCallback(() => {
    setIsMenuOpen(prev => !prev);
  }, []);

  const renderMenuItem = useCallback((href, text) => (
    <a href={href} className="text-gray-600 hover:text-blue-600">{text}</a>
  ), []);

  const renderMobileMenuItem = useCallback((href, text) => (
    <a href={href} className="block py-2 text-gray-600 hover:text-blue-600">{text}</a>
  ), []);

  const renderStepItem = useCallback(({ title, description, icon }, index) => (
    <div key={index} className="text-center mb-8 md:mb-0 md:w-1/4">
      <div className="text-5xl mb-4">{icon}</div>
      <h3 className="font-semibold text-xl mb-2">STEP {index + 1}</h3>
      <p className="text-sm">{title}</p>
      <p className="text-xs mt-2">{description}</p>
    </div>
  ), []);

  return (
    <div className="min-h-screen bg-white">
   

    

 {/* Hero Section */}
 <section className="relative z-10"> {/* Modified: Wrapper for the hero section */}
        <div className="bg-blue-600 text-white py-20 relative"> {/* Modified: Background for the hero text section */}
          <div className="container mx-auto px-4">
            <div className="text-center mb-12">
              <h1 className="text-5xl font-bold mb-6">Find and Fight Deepfakes Affecting Students.</h1>
              <p className="text-xl mb-8">Protect Your Identity Today. Start removing harmful deepfake content with free guides and experts!</p>
            </div>
          </div>

          {/* Map Section - Moved inside the hero section */}
          <div className="app relative z-0 flex justify-center"> {/* Modified: Ensure the map is centered */}
            <div className="max-w-[1000px] w-full"> {/* Modified: Set max width for the map */}
              <AnimatedMapComponent casesData={casesData} />
            </div>
          </div>
        </div>
      </section>

      {/* Table Section */}
      <section className="py-20 max-w-2xl mx-auto p-4 mt-16">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">Find if your school/university is affected:</h2>
        <p className="text-center mb-4">({casesData.length}+ affected)</p>

        {/* Search Bar */}
        <div className="mb-6">
          <input
            type="text"
            placeholder="Search by school, university, or country"
            className="w-full p-2 border rounded"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Table */}
        <div className="relative">
          <div 
            className="table-container overflow-y-auto max-h-96"
            style={{ scrollbarWidth: 'thin', scrollbarColor: '#4A5568 #EDF2F7' }}
          >
            <table className="w-full">
              <thead>
                <tr>
                  <th className="border p-2 bg-gray-200 sticky top-0">School/University Name</th>
                  <th className="border p-2 bg-gray-200 sticky top-0">Country</th>
                  <th className="border p-2 bg-gray-200 sticky top-0">Source</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr key={index}>
                    <td className="border p-2">{item.schoolName}</td>
                    <td className="border p-2">{item.country}</td>
                    <td className="border p-2">
                      <a href={item.source} target="_blank" rel="noopener noreferrer">
                        <button className="bg-blue-300 text-white px-4 py-2 rounded hover:bg-blue-400">View case</button>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Conditional blur effect */}
          {showBlur && filteredData.length > 5 && (
            <div className="table-blur-effect absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-white to-transparent pointer-events-none"></div>
          )}
        </div>
      </div>
    </section>
     {/* Action Buttons Section */}
     <section className="max-w-6xl mx-auto px-4 py-12">
        <div className="flex justify-center">
          <Link to="/contact">
            <button className="bg-blue-300 text-white px-8 py-4 rounded-lg text-lg font-medium hover:bg-blue-400 transition-colors">
              Anonymously report a school/university affected by deepfakes
            </button>
          </Link>
        </div>
      </section>

      {/* Information Sections - Styled as cards */}
      <section className="max-w-6xl mx-auto px-4 py-16">
        <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
          <h2 className="text-4xl font-bold mb-8 text-blue-600">Every Day, Students Like You Are Affected by Deepfakes</h2>
          <p className="text-lg text-gray-700 mb-8">
            Imagine waking up to find your reputation under attack. You're not alone—deepfakes are a growing threat to students everywhere. At Deepfake Help, we believe in empowering YOU to take control. With our guidance and support, you can protect yourself and your future.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <Link to="/find-lawyer">
      <div className="bg-blue-300 rounded-lg p-4 shadow-lg hover:bg-blue-400 transition duration-200 cursor-pointer flex items-center justify-center">
        <h3 className="text-xl font-bold text-white">Supporting Organisations</h3>
      </div>
    </Link>
    <Link to="/guides/deepfake-takedown">
      <div className="bg-blue-300 rounded-lg p-4 shadow-lg hover:bg-blue-400 transition duration-200 cursor-pointer flex items-center justify-center">
        <h3 className="text-xl font-bold text-white">Removal Guides</h3>
      </div>
    </Link>
          </div>
        </div>

        {/* Why We Exist Section */}
        <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
          <h2 className="text-4xl font-bold mb-8 text-blue-600">Why We Exist?</h2>
          <p className="text-lg text-gray-700 mb-6">
            Despite the rapid rise in the number of deepfake incidents, the response remains painfully slow. One key reason?
          </p>
          <p className="text-xl font-bold mb-6">Many incidents. But few reports.</p>
          <p className="text-lg text-gray-700">
            By reporting any deepfake case at a school or university, you help speed up solutions for all deepfake victims. More reported cases mean more attention from the public and media. More attention leads to faster action from governments and educational institutions.
          </p>
          <p className="text-lg text-gray-700">
          Your voice holds immense power. Do you want to be a changemaker? Join us in creating the most comprehensive overview of global deepfake cases targeting students. Together, we can turn the tide and flourish!          </p>
        </div>

        {/* FAQ Section */}
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-4xl font-bold mb-8 text-blue-600">Frequently asked questions</h2>
          <div className="space-y-6">
            {/* Keep your existing FAQ items, styled consistently */}
            <div className="bg-blue-100 rounded-lg p-6">
              <h3 className="text-xl font-bold mb-4 text-blue-600">Who can benefit from Deepfake Help?</h3>
              <p>Deepfake Help is designed for students at all educational levels, including K-12 schools and universities. We provide resources tailored to help everyone navigate deepfake issues effectively.</p>
            </div>
            <div className="bg-blue-100 rounded-lg p-6">
              <h3 className="text-xl font-bold mb-4 text-blue-600">I found a new deepfake case at a school/university. How can I add it to your case list?
              </h3>
              <p>If you encounter a deepfake, we recommend documenting everything first—take screenshots and gather evidence. For our website, we list the names of schools and universities with a link to the reporting article. All details of the case that you have found should be reported directly to your school/university leadership and police. For more supporting organizations, you can refer to our Supporting Organisation page.</p>
            </div>
            <div className="bg-blue-100 rounded-lg p-6">
              <h3 className="text-xl font-bold mb-4 text-blue-600">How can schools and universities get involved with Deepfake Help?</h3>
              <p>Educational institutions can partner with us to access our resources, training materials, and workshops aimed at raising awareness about deepfakes. We encourage schools and universities to join our mission to protect their students and foster a safer digital environment. For more information, please contact us at contact (at) deepfakehelp.com</p>
            </div>
            {/* Repeat for other FAQ items */}
          </div>
        </div>
      </section>
    
    </div>
  );
};



const App = () => {
  return (
    <Router>
      <Header /> {/* Use the Header component */}
      <Routes>
        <Route path="/" element={<DeepfakeVictimSupportLanding />} />
        <Route path="/find-lawyer" element={<FindLawyer />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/guides" element={<GuidesOverview />} />
        <Route path="/guides/deepfake-takedown" element={<GuideTakeDown />} /> {/* Add this line */}

      </Routes>
      <Footer /> {/* Use the Header component */}

    </Router>
  );
};
 

export default App;