// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-blue-600 text-grey p-4 text-center mt-8">
      <p>Dedicated to Protecting Your Digital Identity</p>
      <div className="flex justify-center items-center mt-2">
        <span className="bg-blue-500 p-2 rounded-full mr-2">🛡️</span>
        <span>Copyright © 2024, Deepfake Help. All rights reserved.</span>
      </div>
    </footer>
  );
};

export default Footer;
