import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const FindLawyer = () => {
  const organizations = [
    {
      country: "South Korea",
      name: "Cyber Crime Investigation Bureau (CCIB)",
      description: "Government agency dealing with cybercrime, including online harassment and deepfakes.",
      contact: "police.go.kr\nPhone: 182"
    },
    {
      country: "South Korea",
      name: "Korea Internet & Security Agency (KISA)",
      description: "Provides information on internet security, including reporting online harassment.",
      contact: "kisa.or.kr\nEmail: privacy@kisa.or.kr"
    },
    {
      country: "South Korea",
      name: "Korean Women's Development Institute (KWDI)",
      description: "Offers support and resources for women facing online harassment, including educational materials.",
      contact: "kwdi.re.kr\nEmail: kwdi@kwdi.re.kr"
    },
    {
      country: "Spain",
      name: "Asociación Internacional Teléfono de la Esperanza (ASITES)",
      description: "Provides support and resources for individuals facing mental health issues, including harassment.",
      contact: "telefonoesperanza.org\nPhone: 717 003 717"
    },
    {
      country: "Spain",
      name: "Fundación ANAR",
      description: "Focuses on protecting children and adolescents, offering a helpline for youth experiencing abuse.",
      contact: "anar.org\nPhone: 900 202 010"
    },
    {
      country: "Spain",
      name: "Senda (Cyber Bullying)",
      description: "Offers resources about cyberbullying and harassment for students.",
      contact: "senda.es"
    },
    {
      country: "Australia",
      name: "eSafety Commissioner",
      description: "Provides online safety education and resources for victims of cyberbullying and deepfakes.",
      contact: "esafety.gov.au\nPhone: 1300 363 992"
    },
    {
      country: "Australia",
      name: "ReachOut Australia",
      description: "Offers mental health support and resources for young people dealing with online harassment.",
      contact: "reachout.com"
    },
    {
      country: "United States",
      name: "Cyber Civil Rights Initiative (CCRI)",
      description: "Nonprofit dedicated to fighting online harassment and supporting victims of deepfakes.",
      contact: "cybercivilrights.org\nEmail: info@cybercivilrights.org"
    },
    {
      country: "United States",
      name: "National Center for Missing & Exploited Children (NCMEC)",
      description: "Provides resources about online safety and how to report exploitation and harassment.",
      contact: "missingkids.org\nPhone: 1-800-THE-LOST (1-800-843-5678)"
    },
    {
      country: "Mexico",
      name: "Red por la Infancia (Children's Rights Network)",
      description: "Focused on protecting children's rights in the digital environment, offering resources and advocacy.",
      contact: "redporlainfancia.org"
    },
    {
      country: "Mexico",
      name: "Secretaría de Seguridad y Protección Ciudadana",
      description: "Offers a national helpline for victims of cybercrime and online harassment.",
      contact: "ssp.gob.mx\nPhone: 800 911 2000"
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      

      {/* Main Content */}
      <main className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6 text-center">Find Support Organizations</h1>
        
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-blue-1000 text-red">
                <th className="p-3 text-left">Country</th>
                <th className="p-3 text-left">Organization Name</th>
                <th className="p-3 text-left">Description</th>
                <th className="p-3 text-left">Contact Information</th>
              </tr>
            </thead>
            <tbody>
              {organizations.map((org, index) => (
                <tr key={index} className="border-b">
                  <td className="p-3">{org.country}</td>
                  <td className="p-3">{org.name}</td>
                  <td className="p-3">{org.description}</td>
                  <td className="p-3 whitespace-pre-line">{org.contact}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>

      
    </div>
  );
};

export default FindLawyer;
