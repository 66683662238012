import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer'; // Import the Footer component

const GuidesOverview = () => {
  const guides = [
    // { title: "Understanding Deepfakes", description: "Learn what deepfakes are and how they impact society.", link: "/guides/understanding-deepfakes" },
    // { title: "Legal Implications of Deepfakes", description: "Explore the legal challenges posed by deepfake technology.", link: "/guides/legal-implications" },
    // { title: "How to Spot a Deepfake", description: "Tips and techniques for identifying deepfake content.", link: "/guides/spotting-deepfakes" },
    // { title: "Protecting Yourself from Deepfakes", description: "Strategies to safeguard your digital identity against deepfakes.", link: "/guides/protecting-yourself" },
    { title: "How to take deepfakes down from platforms?", description: "Learn how you can remove deepfakes from websites.", link: "/guides/deepfake-takedown" },
  ];

  return (
    <div className="min-h-screen bg-white">
    
      {/* Main Content */}
      <main className="container mx-auto px-4 py-8">
        <h2 className="text-3xl font-bold mb-6 text-center">Explore Our Guides</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {guides.map((guide, index) => (
            <div key={index} className="border rounded-lg p-4 shadow-md hover:shadow-lg transition-shadow">
              <h3 className="text-xl font-semibold">{guide.title}</h3>
              <p className="text-gray-600 mt-2">{guide.description}</p>
              <Link to={guide.link} className="text-blue-600 hover:underline mt-4 block">
                Read More
              </Link>
            </div>
          ))}
        </div>
      </main>

      
    </div>
  );
};

export default GuidesOverview;